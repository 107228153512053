import { isMobile, isIOS, isAndroid } from "mobile-device-detect";
import Vue from "vue";
import VueAnalytics from "vue-analytics";

import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";

Vue.config.productionTip = false;

Object.assign(window, { isMobile, isIOS, isAndroid });

Vue.use(VueAnalytics, {
  id: "AW-10881317403",
});

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
