<template>
  <div>
    <div class="header">
      <img src="@/assets/images/logo.svg" alt="logo" />
    </div>
    <div class="content">
      <div class="content__hero">
        <div class="content__hero-title text_center" data-aos="fade-up">
          {{ $t("title") }}
        </div>
        <div
          class="content__hero-description text_center"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          {{ $t("slogan") }}
        </div>
      </div>
      <div class="content__inner" data-aos="fade-up" data-aos-duration="1000">
        <div class="content__inner-image">
          <img
            class="content__inner-image__top"
            src="@/assets/images/hearth.png"
            alt="hearth"
            data-aos="zoom-in"
            data-aos-delay="500"
            data-aos-duration="500"
          />
          <img
            class="content__inner-image__top content__inner-image__top--second"
            src="@/assets/images/hearth-small.png"
            alt="hearth-small"
            data-aos="zoom-in"
            data-aos-delay="400"
            data-aos-duration="500"
          />
          <img
            class="content__inner-image__hero"
            src="@/assets/images/woman.png"
            alt="hero"
            data-aos="fade-up"
            data-aos-duration="1000"
          />
          <img
            class="content__inner-image__bottom"
            src="@/assets/images/hand.png"
            alt="hand"
            data-aos="zoom-in-up"
            data-aos-delay="300"
            data-aos-duration="600"
          />
        </div>
        <a
          @click="showVideo"
          class="content__inner-how animate__animated"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1200"
        >
          <span class="content__inner-how__icon">
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 6L0 12L0 0L8 6Z" fill="white" />
            </svg>
          </span>
          <span class="content__inner-how__title" v-html="videoButtonText" />
        </a>
      </div>
    </div>
    <div class="footer animate__animated">
      <a @click="apply" class="footer__button">
        <span class="footer__button-text">{{ $t("applyButtonText") }}</span>
        <span class="footer__button-icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.0113 5.10198L2.11331 16L0 13.8867L10.898 2.98867H4.04532V0H16V11.9547H13.0113V5.10198Z"
              fill="white"
            />
          </svg>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import { Fancybox } from "@fancyapps/ui";

function doAnimation(element, animName, duration, delay) {
  const el = document.querySelector(element);
  let timer;

  function addClass() {
    el.classList.add(animName);
  }
  function removeClass() {
    el.classList.remove(animName);
  }
  setInterval(function () {
    clearTimeout(timer);
    addClass();
    timer = setTimeout(removeClass, duration);
  }, duration + delay);
}

export default {
  name: "HomeView",
  computed: {
    videoButtonText() {
      return this.$t("videoButtonText");
    },
    params() {
      return {
        utmSource: this.$route.query.utmSource || "publisher-monooffer",
        locale: this.$i18n.locale,
        isIOS: window.isIOS,
        isAndroid: window.isAndroid,
      };
    },
    deepLinkUrl() {
      const { utmSource, isIOS } = this.params;
      return isIOS
        ? `https://matchpublive.page.link/?link=https://matchpub.app&isi=1562311685&ibi=com.webmixyazilim.hiii&utm_source=${utmSource}&efr=1`
        : `https://matchpublive.com/?link=https://matchpublive.com?utm_source=${utmSource}&apn=com.matchpublive`;
    },
  },
  mounted() {
    doAnimation(".footer", "animate__shakeX", 1000, 3000);
    doAnimation(".content__inner-how", "animate__tada", 1000, 1000);
  },
  methods: {
    apply() {
      location.href = this.deepLinkUrl;
    },
    showVideo() {
      Fancybox.show([
        {
          autoplay: true,
          src: this.$t("videoUrl"),
          type: "video",
          width: "100%",
          height: "100%",
        },
      ]);
    },
  },
};
</script>
